import { IBannerXAd } from "JS/sponsored/sponsored-types";
import { IProductCarousel } from "JS/types/Carousel";
import { WidgetResource } from "JS/dxp/types/widgets";

export enum DxpInsertedContentType {
	Widget,
	BuyItAgain,
	SponsoredBanner,
	SponsoredCarousel,
};

export type DxpInsertedContent =
	WidgetInsertedContent |
	BuyItAgainInsertedContent |
	SponsoredBannerInsertedContent |
	SponsoredCarouselInsertedContent;

export interface WidgetInsertedContent {
	type: DxpInsertedContentType.Widget,
	data: WidgetResource;
	id: string;
}

export interface BuyItAgainInsertedContent {
	type: DxpInsertedContentType.BuyItAgain,
	data: IProductCarousel;
	id: string;
}

export interface SponsoredBannerInsertedContent {
	type: DxpInsertedContentType.SponsoredBanner,
	data: IBannerXAd;
	id: string;
}

export interface SponsoredCarouselInsertedContent {
	type: DxpInsertedContentType.SponsoredCarousel,
	data: IProductCarousel;
	id: string;
}