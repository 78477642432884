import { inject, InjectionKey, provide, Ref, ref, watch } from "vue";
import { useEventListener } from "JS/composables/event-listener";

const injectionKey = Symbol() as InjectionKey<Readonly<Ref<string>>>;

function computeAndProvidePageTitle(title: () => string): Readonly<Ref<string>> {
	const currentTitle = ref(title());
	useEventListener(window, 'product-list-title-change', e => {
		currentTitle.value = e.detail;
	});

	watch(title, t => {
		currentTitle.value = t;
	});

	provide(injectionKey, currentTitle);
	return currentTitle;
}

export function useDxpPageTitle(): Readonly<Ref<string>>
export function useDxpPageTitle(title: () => string): Readonly<Ref<string>>
export function useDxpPageTitle(title?: () => string): Readonly<Ref<string>> {
	if (typeof title === 'function') {
		return computeAndProvidePageTitle(title);
	}

	return inject(injectionKey);
}