import { computed, inject, InjectionKey, provide, Ref, ref } from "vue";
import { DxpColor, dxpHexColorMap, WidgetColorSetting } from "../types/colors";

const injectionKey = Symbol() as InjectionKey<Ref<DxpColor>>;

export function provideDxpAccentColor(hexColor: Ref<DxpColor>) {
	provide(injectionKey, hexColor);
}

export function useDxpColors(colorSetting: () => WidgetColorSetting) {
	const accentColor = inject(injectionKey);

	const dxpColor = computed<DxpColor>(() => {
		switch (colorSetting()) {
			case WidgetColorSetting.Two:
			case WidgetColorSetting.Three:
				return DxpColor.Gray;

			case WidgetColorSetting.Accent:
				return DxpColor.NavyBlue;

			case WidgetColorSetting.Four:
				return DxpColor.Black;

			default:
				return DxpColor.NavyBlue;
		}
	});

	const backgroundColor = computed<string>(() => dxpHexColorMap[dxpColor.value]);

	const textColor = computed<string>(() => {
		switch (dxpColor.value) {
			case DxpColor.LightBlue:
			case DxpColor.LightYellow:
			case DxpColor.Gray:
				return '#000';
			default:
				return '#fff';
		}
	});

	return  {
		backgroundColor,
		textColor,
		uiButtonColor: computed(() => textColor.value == '#fff' ? 'white-outline' : 'dark-blue-outline'),
		bButtonColor: computed(() => textColor.value == '#fff' ? 'white' : 'blue'),
		textIsWhite: computed(() => textColor.value == '#fff'),
		textIsBlack: computed(() => textColor.value == '#000'),
	 };
}