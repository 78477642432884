import { BaseWidget, WidgetResource, WidgetType } from "./base";
import { CarouselType } from "JS/types/Carousel";
import { DxpProductCarouselProduct, StoryAndItemCarouselLayout } from './shared';
import { WidgetColorSetting } from "../colors";

export interface ItemCarouselWidget extends BaseWidget {
	type: WidgetType.ItemCarouselWidget;
	layout: StoryAndItemCarouselLayout;
	source: ItemCarouselSource;
	title: string;
	text: string;
	linkText: string;
	imageUrl: string;
	imageUrlWebP: string;
	backgroundColor: WidgetColorSetting;
}

export enum ItemCarouselSourceType {
	Invalid,
	RecommendationModel,
	ProductListUrl,
	RecentlyViewed,
}

export interface InvalidItemCarouselSource {
	type: ItemCarouselSourceType.Invalid;
	modelId: number;
}

export interface RecommendationModelItemCarouselSource {
	type: ItemCarouselSourceType.RecommendationModel;
	modelId: number;
}

export interface ProductListUrlItemCarouselSource {
	type: ItemCarouselSourceType.ProductListUrl;
	landingPageUrl: string;
}

export interface RecentlyViewedItemCarouselSource {
	type: ItemCarouselSourceType.RecentlyViewed;
}

type ItemCarouselSource =
	| InvalidItemCarouselSource
	| RecommendationModelItemCarouselSource
	| ProductListUrlItemCarouselSource
	| RecentlyViewedItemCarouselSource;

export interface ItemCarouselWidgetData {
	carouselType: CarouselType;
	carouselName: string;
	products: DxpProductCarouselProduct[];
}

export type ItemCarouselWidgetResource = WidgetResource<ItemCarouselWidget, ItemCarouselWidgetData>;