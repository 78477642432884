var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"root",staticClass:"featured-categories-widget",style:(_setup.cssVarsToStyleString({
		'--fcw-columns--mobile': _setup.columns.mobile,
		'--fcw-columns--tablet': _setup.columns.tablet,
		'--fcw-columns--desktop': _setup.columns.desktop,
		'--fcw-columns--desktop-large': _setup.columns.desktopLarge,
	})),attrs:{"data-fcw-mobile-layout":_setup.mobileLayout}},[_c(_setup.shouldActAsPageHeader ? 'h1' : 'h2',{tag:"component",staticClass:"featured-categories-widget__title",class:{ 'featured-categories-widget__title--page-title dxp-header': _setup.shouldActAsPageHeader }},[_vm._v("\n\t\t"+_vm._s(_setup.shouldActAsPageHeader ? _setup.pageTitle : _vm.widget.data.title)+"\n\t")]),_vm._v(" "),(_vm.widget.data.items.length)?_c('div',{staticClass:"featured-categories-widget__items"},_vm._l((_vm.widget.data.items),function(item,i){return _c('a',{key:i.toString() + item.link,staticClass:"featured-categories-widget-item",class:{ [_setup.hiddenItemClassName]: _setup.mobileLayout === 'stacked' && i >= _setup.mobileInitialShown && !_setup.showAll },style:(_setup.cssVarsToStyleString({
				'--fcw-shift-item-by-spaces--mobile': _setup.mobileLayout === 'stacked' ? _setup.getSpacesToShiftItem(i, _setup.columns.mobile) : undefined,
				'--fcw-shift-item-by-spaces--tablet': _setup.getSpacesToShiftItem(i, _setup.columns.tablet),
				'--fcw-shift-item-by-spaces--desktop': _setup.getSpacesToShiftItem(i, _setup.columns.desktop),
				'--fcw-shift-item-by-spaces--desktop-large': _setup.getSpacesToShiftItem(i, _setup.columns.desktopLarge),
			})),attrs:{"href":item.link,"title":item.label}},[_c('picture',{staticClass:"featured-categories-widget-item__image"},[(item.imageUrlWebP)?_c('source',{attrs:{"srcset":item.imageUrlWebP,"type":"image/webp"}}):_vm._e(),_vm._v(" "),_c('img',{attrs:{"src":item.imageUrl,"alt":"","loading":"lazy"}})]),_vm._v(" "),_c('div',{staticClass:"featured-categories-widget-item__label ellipsis"},[_vm._v("\n\t\t\t\t"+_vm._s(item.label)+"\n\t\t\t")])])}),0):_vm._e(),_vm._v(" "),(_vm.widget.data.items.length > _setup.mobileInitialShown && _setup.mobileLayout === 'stacked')?[_c(_setup.LinkButton,{staticClass:"featured-categories-widget__toggle-button",attrs:{"padded":"","aria-expanded":_setup.showAll ? 'true' : 'false',"aria-describedby":`fcw-show-all-desc-${_setup.id}`},on:{"click":_setup.toggleShowAll}},[_c(_setup.Icon,{attrs:{"name":_setup.showButtonIcon}}),_vm._v("\n\t\t\tView "+_vm._s(_setup.showAll ? 'Less' : 'More')+"\n\t\t\t"),_c(_setup.Icon,{attrs:{"name":_setup.showButtonIcon}})],1),_vm._v(" "),_c('div',{staticStyle:{"display":"none"},attrs:{"id":`fcw-show-all-desc-${_setup.id}`}},[(_setup.showAll)?[_vm._v("\n\t\t\t\tItems previously revealed above this button will be hidden.\n\t\t\t")]:[_vm._v("\n\t\t\t\tItems will be revealed above this button. Focus will move to the first hidden item.\n\t\t\t")]],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }