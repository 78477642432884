import { render, staticRenderFns } from "./HorseCareAndTackHeader.vue?vue&type=template&id=08b8601b&scoped=true"
import script from "./HorseCareAndTackHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./HorseCareAndTackHeader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./HorseCareAndTackHeader.vue?vue&type=style&index=0&id=08b8601b&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b8601b",
  null
  
)

export default component.exports