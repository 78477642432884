import { render, staticRenderFns } from "./PageSeoTextWidget.vue?vue&type=template&id=66cb12f8&scoped=true"
import script from "./PageSeoTextWidget.vue?vue&type=script&setup=true&lang=ts"
export * from "./PageSeoTextWidget.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PageSeoTextWidget.vue?vue&type=style&index=0&id=66cb12f8&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66cb12f8",
  null
  
)

export default component.exports