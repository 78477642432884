export interface DateRange {
	start?: string | null;
	end?: string | null;
}

export interface BaseWidget {
	id: string;
	type: WidgetType;
	dateRange: DateRange;
}

export type WidgetResource<T extends BaseWidget, TData = void> = {
	type: T['type'];
	widget: T;
} & (TData extends void ? {} : { data: TData })

export enum WidgetType {
	InvalidWidget,
	BrandLogosWidget,
	FeaturedCategoriesWidget,
	CardWidget,
	BannerWidget,
	TrendingCarouselWidget,
	AnimalRxBannerWidget,
	PrebuiltHeaderWidget,
	BlogCarouselWidget,
	StoryCarouselWidget,
	ItemCarouselWidget,
	TileGridWidget = 11,
	WinYourWishlistWidget = 12,
	BrandBannerWidget,
}
