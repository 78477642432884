export enum DxpColor {
	NavyBlue = 0,
	LightBlue = 1, // deprecated
	Gray = 2, 
	DarkRed = 3, // deprecated
	Ochre = 4, // deprecated
	Emerald = 5, // deprecated
	Teal = 6, // deprecated
	LightYellow = 8, // deprecated
	Black = 9,
	// next available value: 10
}

export type DxpColorMap = { [K in DxpColor]: string };

// beware: you cannot use WidgetColorSetting to key into this map
export const dxpHexColorMap: DxpColorMap = {
	[DxpColor.NavyBlue]: '#0F2D41',
	[DxpColor.LightBlue]: '#E6E6E6', // formerly #E5F4FF, see DXP-288
	[DxpColor.Gray]: '#E6E6E6',
	[DxpColor.DarkRed]: '#AA141A',
	[DxpColor.Ochre]: '#985800',
	[DxpColor.Emerald]: '#007729',
	[DxpColor.Teal]: '#016B74',
	[DxpColor.LightYellow]: '#FFDDA5',
	[DxpColor.Black]: '#1A1A1A',
};

export enum WidgetColorSetting {
	One,
	Two,
	Three,
	Accent,
	Four,
}