import { onScopeDispose } from "vue";

type Target = Window | Document;

type EventMap<T extends Target> = T extends Window
	? WindowEventMap
	: DocumentEventMap;

export function useEventListener<T extends Target, K extends string & keyof EventMap<T>>(target: T, eventName: K, listener: (event: EventMap<T>[K]) => void) {
	if (typeof target === 'undefined') {
		return;
	}

	const abortController = new AbortController();

	target.addEventListener(
		eventName,
		listener as unknown as (evt: Event) => void,
		{ signal: abortController.signal }
	);

	onScopeDispose(() => {
		abortController.abort();
	});
}