import { ItemCarouselSourceType, ItemCarouselWidget } from "./item-carousel-widget";
import { StoryCarouselWidget } from "./story-carousel-widget";
import { WidgetType } from "./base";

export type StoryAndItemCarouselLink = {
	url: string;
	text: string;
}

export function getStoryOrItemCarouselLink(widget: StoryCarouselWidget | ItemCarouselWidget): StoryAndItemCarouselLink | null {
	if (widget.type === WidgetType.StoryCarouselWidget) {
		const { linkText, linkUrl } = widget;

		if (!linkText || !linkUrl) {
			return null;
		}

		return {
			url: linkUrl,
			text: linkText,
		};
	}

	if (widget.source.type !== ItemCarouselSourceType.ProductListUrl) {
		return null;
	}

	return {
		url: widget.source.landingPageUrl,
		text: widget.linkText
	};
}